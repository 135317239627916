<template>
  <div id="login">
    <div id="login__content" class="mt-10">
      <auth-forms-wrapper height="" width="448px">
        <template #default>
          <login-form />
          <div class="pt-6 text-center">
            <router-link
              id="forgot-password-link"
              class="text-decoration-none"
              :to="$paths.forgotPassword"
            >
              <strong class="subtitle-1 black--text"> Forgot Password</strong>
            </router-link>
          </div>
        </template>
      </auth-forms-wrapper>
    </div>
  </div>
</template>

<script>
import { LoginForm } from "@/components/forms";
import { AuthFormsWrapper } from "@/components/shared";

/**
 * Login view page
 */
export default {
  name: "Login",
  /**
   * ---------- Components ------------
   */
  components: {
    LoginForm,
    AuthFormsWrapper,
  },
};
</script>
